import { Injectable } from '@angular/core';
declare const $: any;

@Injectable({
    providedIn: 'root'
})
export class ImagenesService {

    existImage(image) {
        if (image == null || image == '') {
            return './assets/img/image_placeholder.jpg';
        }
        return image;
    }

}