import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccessGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    return this.checkAuthenticated();
  }

  private checkAuthenticated(): Observable<boolean> {
    return this.authService.acceso()
        .pipe(
          switchMap(isAccesos => {
            if (!isAccesos) {
              this.signOut();
              this.router.navigate(['/404']);

              return of(false);
            }

            return of(true);
          })
        );
  }
  signOut(){
      this.router.navigate(['/login']);
      sessionStorage.clear();
      localStorage.clear();
  }

}
