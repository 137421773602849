import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class HttpHeadersInterceptor implements HttpInterceptor {
   
  constructor(
    private authService: AuthService
    ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    const url='https://graph.facebook.com/v15.0';

    if(!request.url.includes(url)){

      const token: string =this.authService.getAccessToken();
  
      const headers: any = {
          // 'Content-Type': 'application/json'
      };
  
      if (token) {
          headers.Authorization = `Bearer ${token}`;
      }
  
      request = request.clone({
          setHeaders: headers
      });
    }
    

    return next.handle(request);
  }
}
