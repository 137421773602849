import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-preview-dropzone-file',
  templateUrl: './modal-preview-dropzone-file.component.html',
  styleUrls: ['./modal-preview-dropzone-file.component.scss']
})
export class ModalPreviewDropzoneFileComponent implements OnInit {

  file: File;
  dataUrl: any;
  isImage: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.file = this.data.file;
    this.isImage = this.data.isImage;

    this.readAsDataURL();
  }

  private readAsDataURL(): void {
    const reader = new FileReader();

    reader.onloadend = () => {
      this.dataUrl = reader.result;
    };

    if (this.file) {
      reader.readAsDataURL(this.file);
    }
  }

}
