import { Pipe, PipeTransform } from '@angular/core';

const DOCUMENT_ID_INVOICE: number = 1;

@Pipe({
  name: 'filterClientsByDocumentType'
})
export class FilterClientsByDocumentTypePipe implements PipeTransform {

  transform(data: any[], documentTypeId: number, transactionId ?): any[] {
    if (!data) {
      return data;
    }
    if (documentTypeId === 2 || documentTypeId === 7 || documentTypeId === 3 || documentTypeId === 4  ) {
      return data;
    }

    if (documentTypeId === DOCUMENT_ID_INVOICE  ) {
      if(transactionId == '02')
       { return data.filter(item => item.Id.length !== 11 && item.Id.length !== 8 ); }
      else
        return data.filter(item => item.Id.length === 11);
    }

    if (documentTypeId === 1){
      return data.filter(item => item.Id.length !== 11);
    }else{
      return data.filter(item => item.Id.length !== 11  && item.Id.length !== 8 );
    }
        
  }

}
