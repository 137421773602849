import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { GlobalService } from '../../shared/global.service';
import { Subject } from 'rxjs/Subject';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { ModalVideoGuidelinesComponent } from '../modals/modal-video-guidelines/modal-video-guidelines.component';

@Component({
  selector: 'app-module-title',
  templateUrl: './module-title.component.html',
  styleUrls: ['./module-title.component.scss']
})
export class ModuleTitleComponent implements OnInit, OnDestroy {

  @Input() title: string;

  videos: any[] = [];
  componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    private globalService: GlobalService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.globalService.getCurrentVideosByMenu()
        .pipe(
          takeUntil(this.componentDestroyed$)
        )
        .subscribe(value => {
          this.videos = value;
        });
  }

  openModalVideoGuidelines(): void {
    const minWidth: string = this.videos.length > 1 ? '1200px' : '700px';

    this.dialog.open(ModalVideoGuidelinesComponent, {
        data: {
            videos: this.videos
        },
        minWidth,
        minHeight: '400px',
        panelClass: 'custom-modalbox',
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
