import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HttpShowErrorService {

  private show: boolean = true;

  constructor() { }

  setValue(show: boolean) {
      this.show = show;
  }

  getValue() {
      return this.show;
  }

}
