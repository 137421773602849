import { environment } from "environments/environment";



export var GLOBAL = {
    urlClient: 'http://localhost:4200',
//     urlApi: 'http://localhost:8000',  // 'http://34.227.72.250' 'http://142.44.162.56:8002'  http://localhost:8000  angular pruebas
    urlApi: environment.urlApi,  // 'http://34.227.72.250' 'http://142.44.162.56:8002'  http://localhost:8000  angular pruebas
    urlCertificado : '/Files',
    secretKey: 'app_pedrorla',
    domin: environment.domin
}