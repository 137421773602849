import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  get headers() {
    return new HttpHeaders({
      'Content-Type': 'application/json'
    });
  }

  getAccessToken() {
    return sessionStorage.getItem('token');
  }

  getStorage() {
    return sessionStorage.getItem('idusuario');
  }

  acceso():Observable<boolean>{
    const token=this.getAccessToken();
    const idusuario=this.getStorage(); 
    if(token && idusuario){
      return of(true);
    }
    return of(false);
  }
}
