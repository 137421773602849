import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'FiltroNuevo', pure: false
})
export class FiltroNuevo implements PipeTransform {

    transform(value: any, arg: any): any {
           
        const result = [];
      /*   // console.log(key);
        // console.log(arg);
        var array;
        array = (value || []).filter(function (dato) {
          return dato != undefined
        }); */
        
        for (const item of value) {
          if (String(item.id).indexOf(arg)===0) {
            result.push(item);  
          };
        };
        return result;    
    
      }
      

      

}

