import swal from 'sweetalert2';

export class SweetAlert {
    static success(message: string) {
        this.make('Éxito', message, 'success');
    }

    static error(message: string, extras: any = {}) {
        this.make('Error', message, 'error', extras);
    }

    static warning(message: string, extras: any = {}) {
        this.make('Cuidado', message, 'warning', extras);
    }
    static IsEnable(message: string, extras: any = {} ) {
        this.make('Atención', message, 'warning', extras);
        // this.make('Atención', 'La empresa seleccionada cuenta con factura(s) pendientes de pago, no es posible realizar esta operación.', 'warning', extras);
    }
    
    static confirm(message: string, extras = {}) {
        let options: any = {
            showCancelButton: true,
            confirmButtonClass: 'btn btn-primary',
            cancelButtonClass: 'btn btn-danger',
            cancelButtonText: 'Cancelar',
            allowEscapeKey: false,
            buttonsStyling: false,
            allowOutsideClick: false
        };

        options = Object.assign(options, extras);

        return this.make('Confirmar', message, 'warning', options);
    }

    static confirmInput(message: string, extras = {}) {
        let options: any = {
            input: 'text',
            inputLabel: 'Your IP address',
            // inputValue: inputValue,
            showCancelButton: true,
            confirmButtonClass: 'btn btn-primary',
            cancelButtonClass: 'btn btn-danger',
            cancelButtonText: 'Cancelar',
            allowEscapeKey: false,
            buttonsStyling: false,
            allowOutsideClick: false,
            inputValidator: (value) => {
                if (!value) {
                  return 'You need to write something!'
                }
              }
        };

        options = Object.assign(options, extras);

        return this.make('Confirmar', message, 'warning', options);
    }

    static make(title: string, text: string, type: string, extras: any = {}) {
        let config: any = {
            title,
            text,
            type,
            confirmButtonText: 'Aceptar'
        };

        config = Object.assign(config, extras);

        return swal(config);
    }

    static info(message: string,extras = {}){

        let options: any = {
            background: '#fff url(/images/trees.png)',
            backdrop: ` rgba(0,0,123,0.4) url("/images/nyan-cat.gif")
                    left top  no-repeat `,
            showCancelButton: true,
            confirmButtonClass: 'btn btn-primary',
            cancelButtonClass: 'btn btn-danger',
            cancelButtonText: 'Cancelar',
            allowEscapeKey: false,
            buttonsStyling: false,
            allowOutsideClick: false
        };

        options = Object.assign(options, extras);

        return this.make('Continuar', message, 'info', options);

    }

    static infoInput(message: string, extras = {}) {
        let options: any = {      
            input: 'password',
        //     html:
        // '<label for="swal2-input" class="swal2-input-label">Contraseña</label>'+
        // '<input id="swal-password1" class="swal2-input" type="password" autocomplete="off" placeholder="ingrese la contraseña">' ,
            inputPlaceholder: 'Ingrese su contraseña',     
            backdrop: ` rgba(0,0,123,0.4) url("/images/nyan-cat.gif")
                    left top  no-repeat `,
            showCancelButton: true,
            confirmButtonClass: 'btn btn-primary',
            cancelButtonClass: 'btn btn-danger',
            cancelButtonText: 'Cancelar',
            allowEscapeKey: false,
            buttonsStyling: false,
            allowOutsideClick: false,
            inputAttributes: {
                maxlength: 50,
                autocomplete: 'off' 
            },
            inputValidator: (value) => {
                if (!value) {
                  return 'Necesita ingresa la contraseña!'
                }
            }             
        };

        options = Object.assign(options, extras);

        return this.make('Confirmar', message, 'warning', options);
    }

    static listInfo(message: string, list: any ,extras = {}){

        let text=''
        let height='';
        console.log( list.length , list.length>6);
        if(list.length>6){
           height='height: 200px;'; 
        } 
        for (const obj of list) {
            text +=' <li class="list-group-item p-1"> '+ obj.descripcioncorta +'-'+ obj.serie +'-'+ obj.numero +' </li> '
        }

        let options: any = {

            showCancelButton: false,
            confirmButtonClass: 'btn btn-primary',
            html: `
            <div style="${height} overflow: overlay; padding-left: 50px;" > 
                <ul  class="list-group">
                    ${ text } 
                </ul> 
            </div> 
                    `
        };

        options = Object.assign(options, {});

        return this.make('Comprobantes Faltantes', message, 'warning', options);

        
    }


    static confirmPayment(message: string,extras = {}){
        let options: any = {
            title: 'Atención',
            showDenyButton: true,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Ir a Pagar',
            // denyButtonText: `Pagar`
        };

        // let options: any = {
        //     background: '#fff url(/images/trees.png)',
        //     backdrop: ` rgba(0,0,123,0.4) url("/images/nyan-cat.gif")
        //             left top  no-repeat `,
        //     showCancelButton: true,
        //     confirmButtonClass: 'btn btn-primary',
        //     cancelButtonClass: 'btn btn-danger',
        //     cancelButtonText: 'Cancelar',
        //     allowEscapeKey: false,
        //     buttonsStyling: false,
        //     allowOutsideClick: false
        // };

        options = Object.assign(options, extras);

        return this.make('Continuar', message, 'info', options);

    }

    // Swal.fire({
    //     title: 'Do you want to save the changes?',
    //     showDenyButton: true,
    //     showCancelButton: true,
    //     confirmButtonText: 'Save',
    //     denyButtonText: `Don't save`,
    //   }).then((result) => {
    //     /* Read more about isConfirmed, isDenied below */
    //     if (result.isConfirmed) {
    //       Swal.fire('Saved!', '', 'success')
    //     } else if (result.isDenied) {
    //       Swal.fire('Changes are not saved', '', 'info')
    //     }
    //   })




}
