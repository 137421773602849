import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar.component';
import { FormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';
import { MatButtonModule,MatInputModule, MatSelectModule } from '@angular/material';

@NgModule({
    imports: [ 
        RouterModule, 
        CommonModule, 
        MatButtonModule, 
        NgSelectModule, 
        FormsModule, 
        MatButtonModule,
        MatInputModule,
        MatSelectModule
    ],
    declarations: [ NavbarComponent ],
    exports: [ NavbarComponent ]
})

export class NavbarModule {}
