import { NgModule } from '@angular/core';
import { LengthPipe } from './Length.pipe';
import { FiltrarPipe } from './filtrar.pipe';
import { FiltroNuevo } from './filtroNuevo.pipe';
import { GroupPipe } from './group.pipe';
import { PaginatePipe } from './paginate.pipe';
import { UrlSafePipe } from './url-safe.pipe';
import { FilterClientsByDocumentTypePipe } from './filter-clients-by-document-type.pipe';
import { FilterArrayObjectsPipe } from './filter-array-objects.pipe';
const PIPES_SHARES = [
    LengthPipe,
    FiltrarPipe,
    FiltroNuevo,
    GroupPipe,
    PaginatePipe,
    UrlSafePipe,
    FilterClientsByDocumentTypePipe,
    FilterArrayObjectsPipe
];

@NgModule({
    declarations: [
        ...PIPES_SHARES
    ],
    imports: [],
    exports: [
        ...PIPES_SHARES
    ]
})

export class PipesModule {}
