import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpInterceptor,
  HttpErrorResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { StatusCodes } from '../utils/StatusCodes';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// import { AuthService } from '../auth/auth.service';
import { SweetAlert } from '../utils/SweetAlert';
import { HttpShowErrorService } from '../services/http-show-error.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    // private authService: AuthService,
    private httpShowErrorService: HttpShowErrorService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
       catchError((err: HttpErrorResponse) => {
        if (this.httpShowErrorService.getValue()) {
          this.handleErrors(err);
        }

        return throwError(err);
       })
    );
  }

  private handleErrors(err: HttpErrorResponse) {
      const { error, status, message } = err;

      if (err.status === 0) {
        return SweetAlert.error(message);
      }

      if (status === StatusCodes.HTTP_UNAUTHORIZED) {
        return this.resolveUnauthorizedError(error);
      }

      if (status === StatusCodes.HTTP_UNPROCESSABLE_ENTITY) {
        return this.showValidationErrors(error);
      }

      return SweetAlert.error(error.message || message);
  }

  private resolveUnauthorizedError(error: any) {
    const { message } = error;
    const isAuthenticated: boolean = false;//this.authService.getAccessToken() ? true : false;

    if (!isAuthenticated) {
        return SweetAlert.error(message);
    }

    SweetAlert.confirm(message, {
        title: 'Error',
        type: 'error',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showCancelButton: false
    }).then((result: any) => {
        if (result.value) {

            // this.authService.clearStorage();
                console.log(isAuthenticated);
            if (isAuthenticated) {
                this.router.navigate(['pages/login']);
            }
        }
    });
  }

  private showValidationErrors(error: any) {
    const { errors } = error;

    let html = `<br /><ul class="text-left">`;

    errors.forEach((item: any) => {
        html += `<li>${ item.message }</li>`;
    });

    html += `</ul>`;

    SweetAlert.warning(null, { html });
  }
}
