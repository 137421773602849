import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';


//ImporLogin

import { GlobalService }  from './shared/global.service';


import { ValidAccessResolver } from './pages/valid-access/valid-access.resolver';
import { AccessGuard } from './guards/access.guard';


declare var rou: GlobalService;

// const routing = rou.routing();

export const AppRoutes: Routes = [    
    // {
    //     path: '',
    //     redirectTo: 'valid-access',
    //     pathMatch: 'full'
    // },
    {
        path: '',
        component: AdminLayoutComponent,
        children:
         [
            {
                path: 'valid-access/:idempresa/:idsucursal/:idusuario/:idaniopro/:idmes/:token',
                loadChildren: './pages/valid-access/valid-access.module#ValidAccessModule',
                resolve: {
                    validAccess: ValidAccessResolver
                  }
            },
            // {

            // },
            {
                path:'404',
                loadChildren: './shared/error-page/error-page.module#ErrorPageModule'
            }

        ]
    },
    {
        path: 'libro-ventas',
        component:AdminLayoutComponent,
        canActivate:[AccessGuard],
        loadChildren: './pages/libro-ventas/libro-ventas.module#LibroVentasModule',
    },
    {
        path:'**',
        redirectTo:'404'
    }

];