import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterArrayObjects'
})
export class FilterArrayObjectsPipe implements PipeTransform {

  transform(items: any[], value: string, column: string): any {
    if (items.length === 0 || !value) {
      return items;
    }

    return items.filter(item => (item[column].toLowerCase()).indexOf(value.toLowerCase()) !== -1);
  }

}
