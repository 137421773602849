import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';

export class HttpDateInterceptor implements HttpInterceptor {
    // Migrated from AngularJS https://raw.githubusercontent.com/Ins87/angular-date-interceptor/master/src/angular-date-interceptor.js
    iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // var body = this.convertToDate(JSON.parse(request.body));

        // if (request.method == 'POST') {

        //     const reqCopy = request.clone({
        //         body
        //     })

        //     return next.handle(reqCopy);
        // }

        return next.handle(request);

        //   }
    }

    convertToDate(body) {
        if (body === null || body === undefined) {
            return body;
        }

        if (typeof body !== 'object') {
            return body;
        }

        for (const key of Object.keys(body)) {
            const value = body[key];
            if (this.isIso8601(value)) {
                body[key] = new Date(value).toLocaleDateString();
            } else if (typeof value === 'object') {
                this.convertToDate(value);
            }
        }
        return body;
    }

    isIso8601(value) {
        if (value === null || value === undefined) {
            return false;
        }
        console.log(this.iso8601.test(value));
        return this.iso8601.test(value);
    }
}