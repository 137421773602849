import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';



import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
  MatBadgeModule,
} from '@angular/material';
import {  MatPaginatorModule} from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { TextMaskModule } from 'angular2-text-mask';


import { OverlayModule } from '@angular/cdk/overlay';

import { AppComponent } from './app.component';

import { NavbarModule } from './shared/navbar/navbar.module';
import { FooterModule } from './shared/footer/footer.module';
import { FixedpluginModule } from './shared/fixedplugin/fixedplugin.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PipesModule } from './pipes/pipes.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
// import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
// import { Usuarios2formComponent } from './layouts/usuariosregister/RegisterForm/Usuarios2.component';


import { HomeComponent } from './home/home.component';

import { AppRoutes } from './app.routing';
import { ImagenesService } from './notifications/imagenes.service';
import { ExcelService } from './services/excel.service';
import { HttpHeadersInterceptor } from './interceptors/http-headers.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { HttpCancelInterceptor } from './interceptors/http-cancel.interceptor';
import { HttpDateInterceptor } from './interceptors/http-date.interceptor';
import { ModalVideoGuidelinesComponent } from './components/modals/modal-video-guidelines/modal-video-guidelines.component';
// import { ValidAccessComponent } from './pages/valid-access.component';
// import { IngresoMolinoFormComponent } from './Mina/Procesos/ingreso-molino-page/ingreso-molino-form/ingreso-molino-form.component';
// import { IngresoSalaverryFormComponent } from './Mina/Procesos/ingreso-salaverry-page/ingreso-salaverry-form/ingreso-salaverry-form.component';
// import { CompraMineralFormComponent } from './Mina/Procesos/compra-mineral-page/compra-mineral-form/compra-mineral-form.component';
// import { CompraMineralListComponent } from './Mina/Procesos/compra-mineral-page/compra-mineral-list/compra-mineral-list.component';
// import { BalanzaTableFormComponent } from './Mina/Procesos/balanza-page/balanza-table-form/balanza-table-form.component';
import { ModalPreviewDropzoneFileComponent } from './components/modals/modal-preview-dropzone-file/modal-preview-dropzone-file.component';
import { ModuleTitleComponent } from './components/module-title/module-title.component';
import { AutoFocusDirective } from './utils/auto-focus.directive';
// import { ErrorPageComponentComponent } from './shared/error-page-component.component';
// import { ErrorPageComponentComponent } from './shared/error-page-component/error-page-component.component';



@NgModule({
  exports: [
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatBadgeModule,
    NgSelectModule,
    TextMaskModule,
    OverlayModule,
  ],
  declarations: []
})
export class MaterialModule { }

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule.forRoot(AppRoutes),
    HttpModule,
    MaterialModule,
    NgSelectModule,
    MatNativeDateModule,
    NavbarModule,
    FooterModule,
    FormsModule,
    ReactiveFormsModule,
    FixedpluginModule,
    HttpClientModule,
    PipesModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,

    HomeComponent,
    ModalVideoGuidelinesComponent,
    ModalPreviewDropzoneFileComponent,
    ModuleTitleComponent,
    AutoFocusDirective
  ],
  entryComponents: [
    ModalVideoGuidelinesComponent,
    ModalPreviewDropzoneFileComponent,
    ModuleTitleComponent
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    ImagenesService,
    ExcelService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCancelInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpHeadersInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpDateInterceptor,
      multi: true
    },
  ]
})
export class AppModule {}
