// import { AuthService } from '../auth/auth.service';
import { GLOBAL } from '../shared/global.url';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Routes } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';


@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  
  // private enterprise = new BehaviorSubject<any>([]);
  // public customEnterprise = this.enterprise.asObservable();

  private menu = new BehaviorSubject<any>([]);
  private sidebarLoaded = new BehaviorSubject<boolean>(false);
  public customMenu = this.menu.asObservable();
  public apiUrl: any = GLOBAL.urlApi;

  private currentVideosByMenu$ = new BehaviorSubject<any>([]);
  private currentPermitsByMenu$ = new BehaviorSubject<any>([]);

  constructor(
    public http: HttpClient,
    // public _auth: AuthService
  ) {}

  public getObservableSidebarLoaded(): BehaviorSubject<boolean> {
    return this.sidebarLoaded;
  }

  public setMenus(): void {
    this.getMenus(sessionStorage.getItem('idmodulo'), sessionStorage.getItem('idplan')).subscribe(r => {
      this.menu.next(r);
    });
  }
  
  // public setEnterprises(): void {
  //   this.getempresas().subscribe(r => {
  //     this.enterprise.next(r);
  //   });
  // }

  getempresas(): Observable<any> {
    return this.http.get(`${this.apiUrl}/Global/getempresas`);
  }

  getsucursales(companyId: any, userId: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/Global/getsucursales/${companyId}/${userId}`);
  }

  getMeses(): Observable<any> {
    return this.http.get(`${this.apiUrl}/Global/getMeses`);
  }

  getAnio(idempresa:string): Observable<any> {
    return this.http.get(`${this.apiUrl}/Global/getAnio/${idempresa}`);
  }

  getModulos(userId): Observable<any> {
    return this.http.get(`${this.apiUrl}/Global/getModulos/${userId}`);
  }

  getMenus(moduleId: any, idplan: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/Global/getMenus/${moduleId}/${idplan}`);
  }

  setCurrentVideosByMenu(videos: any[]) {
    this.currentVideosByMenu$.next(videos);
  }

  getCurrentVideosByMenu() {
    return this.currentVideosByMenu$;
  }
  setCurrentPermitsByMenu(videos: any[]) {
    this.currentPermitsByMenu$.next(videos);
  }

  getCurrentPermitsByMenu() {
    return this.currentPermitsByMenu$;
  }

  getIsEnable(){
    return this.sidebarLoaded;
  }


  getmessages(): Observable<any> {
    return this.http.get(`${this.apiUrl}/Global/getmessages`);
  }

}
