import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// import { GlobalService } from '../shared/global.service';


declare const $: any;

@Component({
    selector: 'app-footer-cmp',
    templateUrl: 'footer.component.html',
    styleUrls: ['footer.component.scss']
})

export class FooterComponent implements OnInit {
    test: Date = new Date();

    Empresas = [];
    EmpresasName: string;
    filternombrerazon: string = '';
    Sucursales = [];
    idsucursal: any;
    
    listmeses = [];
    idmes: any;

    listanios = [];
    idaniopro: any;

    //vVar_gloFooter


    constructor(
        private router: Router,
        // private _globalService: GlobalService


    ) { }

    ngOnInit() {
        // this._globalService.getempresas(sessionStorage.getItem('idusuario')).subscribe((data: any) => {
        //     this.Empresas = data;
        //     this.ChangeEmpresas(this.Empresas[0]);
        // })

        // this._globalService.getMeses().subscribe(r => {
        //     this.listmeses = r;
        //     this.idmes = this.listmeses[0].idmes;
        //     this.changeMeses(this.listmeses[0]);
        // });

        // this._globalService.getAnio().subscribe(r => {
        //     this.listanios = r;
        //     this.idaniopro = this.listanios[0].idaniopro;
        //     this.changeAnios(this.listanios[0]);
        // });
        //ComboFooter

    }
    // ChangeEmpresas(empresas) {
    //     this.router.navigate(['home']);
    //     sessionStorage.setItem('idempresa', empresas.idempresa); 
    //     sessionStorage.setItem('nombrerazon', empresas.nombrerazon);
    //     sessionStorage.setItem('ruc', empresas.ruc);
    //     sessionStorage.setItem('direccion', empresas.direccion);
    //     sessionStorage.setItem('distrito',empresas.distrito);
    //     sessionStorage.setItem('correoEmpresa', empresas.email);
    //     this.EmpresasName = empresas.nombrerazon;
    //     this._globalService.getsucursales(empresas.idempresa,sessionStorage.getItem('idusuario')).subscribe((data: any) => {
    //         this.Sucursales = data;
    //         this.idsucursal = this.Sucursales[0].idsucursal; sessionStorage.setItem('idsucursal', this.Sucursales[0].idsucursal); 
    //         sessionStorage.setItem('sucursal', this.Sucursales[0].descripcion);
    //     })

    //     $("#EmpresasModal").modal('hide');
    //     this.Clearnombrerazon();
    // }

    Changenombrerazon($event) {
        if ($event) {
            this.filternombrerazon = $event.ItemName;
        } else {
            this.filternombrerazon = '';
        }
    }

    Clearnombrerazon() {
        this.filternombrerazon = '';
    }

    ChangeSucursales(event) {
        this.router.navigate(['dashboard']);
        sessionStorage.setItem('idsucursal', event.idsucursal);

    }

    changeMeses(event){
        this.router.navigate(['dashboard']);
        sessionStorage.setItem('idmes', event.idmes);
        sessionStorage.setItem('desmes', event.desmes);
    }

    changeAnios(event){
        this.router.navigate(['dashboard']);
        sessionStorage.setItem('idaniopro', event.idaniopro);
    }
    
    //changeCombo


}